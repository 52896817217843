import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import CustomDatePicker from "../../GenericComponents/CustomDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomTextField from "../../GenericComponents/CustomTextField";
import traineeServices from "../../Services/TraineeServices";
import trainingCourseServices from "../../Services/TrainingCourseServices";
import { useLocation, useParams } from "react-router-dom";
import traineeCertificateServices from "../../Services/TraineeCertificateServices";
import CustomFileInput from "../../GenericComponents/CustomFileInput";
import Form_Instructions from "../../Content/Instructions/Form_Instructions";

const sectionGridStyle = {
  borderRadius: 2,
  p: 2,
  boxShadow: 1,
  backgroundColor: "#F5F5F5",
};

const sectionHeadingStyle = {
  fontFamily: "inherit",
  fontWeight: "bold",
  textTransform: "uppercase",
  mx: 2,
};

const sectionContentStyle = {
  display: "grid",
  gap: 2,
  mx: 2,
  mt: 2,
  mb: 1,
  p: 3,
  backgroundColor: "white",
  borderRadius: 2,
};

function Trainee() {
  const location = useLocation();
  const stateProp = location.state;

  const path = window.location.pathname;
  const isRegister = path.includes("register") ? true : false;

  const [open, setOpen] = useState(true);

  // Form field states
  const [firstName, setFirstName] = useState(""); // Personal Information
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(isRegister ? "" : stateProp.email);
  const [phoneNo, setPhoneNo] = useState("");
  const [altPhoneNo, setAltPhoneNo] = useState("");
  const [gender, setGender] = useState(null);
  const [dob, setDob] = useState(null);

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [landmark, setLandmark] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");

  const [qualification, setQualification] = useState(null);
  const [degree, setDegree] = useState(null);
  const [otherDegree, setOtherDegree] = useState("");
  const [branch, setBranch] = useState(null);
  const [otherBranch, setOtherBranch] = useState("");
  const [yop, setYop] = useState(null);
  const [cgpa, setCgpa] = useState("");
  const [collegeName, setCollegeName] = useState("");

  const [skills, setSkills] = useState(isRegister ? "" : stateProp.skills);
  const [paymentReceiptFile, setPaymentReceiptFile] = useState(null);

  const [agreementChecked, setAgreementChecked] = useState(false);

  // Form Field error states
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [dobError, setDobError] = useState("");

  const [address1Error, setAddress1Error] = useState("");
  const [landmarkError, setLandmarkError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [pincodeError, setPincodeError] = useState("");

  const [qualificationError, setQualificationError] = useState("");
  const [degreeError, setDegreeError] = useState("");
  const [otherDegreeError, setOtherDegreeError] = useState("");
  const [branchError, setBranchError] = useState("");
  const [otherBranchError, setOtherBranchError] = useState("");
  const [yopError, setYopError] = useState("");
  const [cgpaError, setCgpaError] = useState("");
  const [collegeNameError, setCollegeNameError] = useState("");

  const [skillsError, setSkillsError] = useState("");
  const [paymentReceiptFileError, setPaymentReceiptFileError] = useState(null);

  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(
    isRegister ? null : stateProp.trainingCourse
  );

  // Autocomplete props

  const courseProps = {
    options: courses,
    getOptionLabel: (option) =>
      option.name +
      " (" +
      option.duration +
      (option.duration === "1" ? " month)" : " months)"),
  };

  const genderList = ["Male", "Female", "Others"];
  const genderProps = {
    options: genderList,
  };

  const qualificationsList = ["Post Graduate", "Graduate"];
  const qualificationProps = {
    options: qualificationsList,
  };

  const mastersDegreeList = [
    "Dual Degree M.E",
    "Dual Degree M.Sc",
    "Dual Degree M.Tech",
    "Integrated MBA",
    "M.A (Master of Arts)",
    "M.B.A(Masters in Business Administration) - Tier 2 / Tier3 only",
    "M.C.M(Master of Computer Management)",
    "M.C.A(Master of Computer Application)",
    "M.Com (Master Of Commerce)",
    "M.E.(Master of Engineering)",
    "M.FA (Master of Fine Arts)",
    "M.Pharm (Master of Pharmacy)",
    "M.Sc(Master of Science)",
    "M.Sc (IT or CSC)",
    "M.Tech.(Master of Technology)",
    "MMS/ PGDM NonPremium Institute(Tier 2 / Tier 3)",
    "Master of Design",
    "MS / M.Sc(Master of Science)",
    "PGDCA(Post Graduate Diploma in Computer Application)",
    "Post Graduate Diploma",
    "Tech PG - Relevant (M Tech/ ME/ MS/ PGDCM/ IIS)",
    "Other",
  ];

  const bachelorsDegreeList = [
    "B.A. (Bachelor of Arts)",
    "B.ActSc (Bachelors in Actuarial Science)",
    "B.Agri & RD (Bachelors in Agriculture & Rural Development)",
    "B.A.F (Bachelors in Accounting & Finance)",
    "B.B.I (Bachelors in Banking & Insurance)",
    "B.B.S (Bachelor in Business Studies)",
    "B.C.A (Bachelor of Computer Application)",
    "B.Com. (Bachelor of Commerce)",
    "B.Design (Bachelor of Design)",
    "B.E. (Bachelor of Engineering)",
    "B.Ed. (Bachelor of Education)",
    "B.F.M (Bachelors of Financial Markets)",
    "B.FA (Bachelor of Fine Arts)",
    "B.L.I.Sc. (Bachelors of Library & Information Science)",
    "B.M.M (Bachelors of Mass Media)",
    "BMS (Bachelor of Management Studie)",
    "B.Pharma (Bachelor of Pharmacy)",
    "B.Plan (Bachelor of Planning)",
    "B.PSc (Bachelors in Political Sciences)",
    "B.S.Micr (Bachelor in Science Microbiology)",
    "B.Sc (Bachelor of Science)",
    "B.Tech. (Bachelor of Technology)",
    "B.Voc (Bachelor of Vocation)",
    "BBA (Bachelor of Business Administration)",
    "BBiot (Bachelor of Biotechnology)",
    "Other",
  ];
  const degreeProps = {
    options:
      qualification === "Post Graduate"
        ? mastersDegreeList
        : bachelorsDegreeList,
  };

  const branchList = ["CSE", "ISE", "Mech.", "Civil", "Other"];
  const branchProps = {
    options: branchList,
  };

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent right-click context menu
  };

  const handleFirstNameChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setFirstNameError("");
    setFirstName(newValue);
  };

  const handleLastNameChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setLastNameError("");
    setLastName(newValue);
  };

  const handleEmailChange = (event) => {
    if (isRegister) {
      const { value } = event.target;
      const newValue = value.replace(/[^A-Za-z0-9@._]/g, ""); // Only allow alphanumeric and Special  characters
      setEmailError("");
      setEmail(newValue);
    }
  };

  const handlePhoneNochange = (event) => {
    const { value } = event.target;
    // Only allow numeric characters and limit to 10 characters
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 10);
    setPhoneNoError("");
    setPhoneNo(newValue);
  };

  const handleAltPhoneNochange = (event) => {
    const { value } = event.target;
    // Only allow numeric characters and limit to 10 characters
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 10);

    setAltPhoneNo(newValue);
  };

  const handleGenderChange = (event, value) => {
    setGenderError("");
    setGender(value);
  };

  const handleDobChange = (value) => {
    setDobError("");
    setDob(value);
  };

  const handleAddress1Change = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9._,\s-]/g, ""); // \s represents space character
    setAddress1Error("");
    setAddress1(newValue);
  };

  const handleAddress2Change = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9._,\s-]/g, ""); // \s represents space character  // Only allow alphanumeric and Special  characters
    setAddress2(newValue);
  };

  const handleLandmarkChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9._,\s-]/g, ""); // \s represents space character  // Only allow alphanumeric and Special  characters
    setLandmarkError("");
    setLandmark(newValue);
  };

  const handleCityChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z ]/g, ""); // Only allow alphanumeric and Special  characters
    setCityError("");
    setCity(newValue);
  };

  const handleStateChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z ]/g, ""); // Only allow alphanumeric and Special  characters
    setStateError("");
    setState(newValue);
  };

  const handlePincodeChange = (event) => {
    const { value } = event.target;
    // Only allow numeric characters and limit to 6 characters
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 6);
    setPincodeError("");
    setPincode(newValue);
  };

  const handleQualificationChange = (event, value) => {
    setQualificationError("");
    setQualification(value);
  };

  const handleDegreeChange = (event, value) => {
    setDegreeError("");
    setDegree(value);
  };

  const handleOtherDegreeChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9._,-]/g, ""); // Only allow alphanumeric and Special  characters
    setOtherDegreeError("");
    setOtherDegree(newValue);
  };

  const handleBranchChange = (event, value) => {
    setBranchError("");
    setBranch(value);
  };

  const handleOtherBranchChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9._,-]/g, ""); // Only allow alphanumeric and Special  characters
    setOtherBranchError("");
    setOtherBranch(newValue);
  };

  const handleYopChange = (value) => {
    setYopError("");
    setYop(value);
  };

  const handleCgpaChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^0-9.]/g, "");
    setCgpaError("");
    setCgpa(newValue);
  };

  const handleCollegeNameChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z .]/g, "");
    setCollegeNameError("");
    setCollegeName(newValue);
  };

  const handleSkillsChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9@._ ,]/g, ""); // Only allow alphanumeric and Special  characters
    setSkillsError("");
    setSkills(newValue);
  };

  const handleChangePaymentReceipt = (newFile) => {
    setPaymentReceiptFileError("");
    setPaymentReceiptFile(newFile);
  };

  const handleAgreementCheckboxChange = (event) => {
    setAgreementChecked(event.target.checked);
  };

  // Form Field error handlers
  const validateFirstName = () => {
    setFirstNameError(firstName ? "" : "This field is required");
  };

  const validateLastName = () => {
    setLastNameError(lastName ? "" : "This field is required");
  };

  const validateEmail = () => {
    setEmailError(email ? "" : "This field is required");
  };

  const validatePhoneNo = () => {
    setPhoneNoError(phoneNo ? "" : "This field is required");
  };

  const validateGender = () => {
    setGenderError(gender ? "" : "This field is required");
  };

  const validateDob = () => {
    setDobError(dob != null ? "" : "This field is required");
  };

  const validateAddress1 = () => {
    setAddress1Error(address1 ? "" : "This field is required");
  };

  const validateLandmark = () => {
    setLandmarkError(landmark ? "" : "This field is required");
  };

  const validateCity = () => {
    setCityError(city ? "" : "This field is required");
  };

  const validateState = () => {
    setStateError(state ? "" : "This field is required");
  };

  const validatePincode = () => {
    setPincodeError(pincode ? "" : "This field is required");
  };

  const validateQualification = () => {
    setQualificationError(qualification ? "" : "This field is required");
  };

  const validateDegree = () => {
    setDegreeError(degree ? "" : "This field is required");
  };

  const validateOtherDegree = () => {
    setOtherDegreeError(otherDegree ? "" : "This field is required");
  };

  const validateBranch = () => {
    setBranchError(branch ? "" : "This field is required");
  };

  const validateOtherBranch = () => {
    setOtherBranchError(otherBranch ? "" : "This field is required");
  };

  const validateYop = () => {
    setYopError(yop != null ? "" : "This field is required");
  };

  const validateCgpa = () => {
    setCgpaError(cgpa ? "" : "This field is required");
  };

  const validateCollegeName = () => {
    setCollegeNameError(collegeName ? "" : "This field is required");
  };

  const validateSkills = () => {
    setSkillsError(skills ? "" : "This field is required");
  };

  const validatePaymentReceiptFile = () => {
    setPaymentReceiptFileError(
      paymentReceiptFile ? "" : "This field is required"
    );
  };

  const validateFormFields = () => {
    validateFirstName();
    validateLastName();
    validateEmail();
    validatePhoneNo();
    validateGender();
    validateDob();
    validateAddress1();
    validateLandmark();
    validateCity();
    validateState();
    validatePincode();
    validateQualification();
    validateDegree();
    validateOtherDegree();
    validateBranch();
    validateOtherBranch();
    validateYop();
    validateCgpa();
    validateCollegeName();
    validateSkills();
    validatePaymentReceiptFile();

    const isFormValid =
      (firstName &&
        lastName &&
        email &&
        phoneNo &&
        gender &&
        dob != null &&
        address1 &&
        landmark &&
        city &&
        state &&
        pincode &&
        qualification &&
        (degree === "Other" ? otherDegree : degree) &&
        (branch === "Other" ? otherBranch : branch) &&
        yop != null &&
        cgpa &&
        collegeName) ||
      (isRegister ? skills && paymentReceiptFile : false);
    return isFormValid;
  };

  const handleSubmit = async (event) => {
    if (validateFormFields()) {
      const files = [paymentReceiptFile];
      const timestamp = new Date().toISOString();
      const formattedDob = dob.format("DD-MM-YYYY");
      const formattedYop = yop.format("YYYY");
      const jsonData = {
        trainingCourseId: selectedCourse.id,
        name: firstName + " " + lastName,
        email: email,
        phoneNo: altPhoneNo ? phoneNo + " / " + altPhoneNo : phoneNo,
        gender: gender,
        dob: formattedDob,
        address: address2 ? address1 + ", " + address2 : address1,
        state: state,
        landmark: landmark,
        city: city,
        pinCode: pincode,
        degree: degree !== "Other" ? degree : otherDegree,
        branch: branch !== "Other" ? branch : otherBranch,
        yop: formattedYop,
        cgpa: cgpa,
        collegeName: collegeName,
        skills: skills,
        timestamp: timestamp,
      };
      console.log(jsonData);

      if (isRegister) {
        let data = await traineeServices.AddTrainee(files, jsonData);

        if (data === false) {
          console.log("Something went wrong.");
        } else {
          if (data.code === 200) {
            console.log("Trainee Added Successfully. ", data.responseBody);
            // resetValues();
            window.location.replace("/success");
          } else {
            console.log("Invalid tr details.");
          }
        }
      } else {
        let data = await traineeCertificateServices.AddCertificateDetails(
          jsonData
        );

        if (data.code === 200) {
          console.log(
            "Certificate Details Added Successfully. ",
            data.responseBody
          );
          // resetValues();
          window.location.replace("/success");
        } else if (data.code === 209) {
          console.log("209: " + data.responseBody);
          if (data.responseBody.includes("Email")) {
            setEmailError("This Email already exists");
          }
          if (data.responseBody.includes("Phone number")) {
            setPhoneNoError("This Phone Number already exists");
          }
        } else {
          console.log("Invalid trainee details.");
        }
      }
    }
  };

  // const resetValues = () => {
  //   setFirstName("");
  //   setLastName("");
  //   setEmail("");
  //   setPhoneNo("");
  //   setGender(null);
  //   setDob(null);
  //   setAddress1("");
  //   setLandmark("");
  //   setCity("");
  //   setState("");
  //   setPincode("");
  //   setPermanentAddress1("");
  //   setPermanentLandmark("");
  //   setPermanentCity("");
  //   setPermanentState("");
  //   setPermanentPincode("");
  //   setDegree(null);
  //   setOtherDegree("");
  //   setBranch(null);
  //   setOtherBranch("");
  //   setYop(null);
  //   setCgpa("");
  //   setCollegeName("");
  //   setSkills("");
  //   setYearsOfExp("");
  //   setDateOfAvailability(null);
  // };

  let { courseId } = useParams();

  useEffect(() => {
    const fetchCourses = async () => {
      let data = await trainingCourseServices.getTrainingCourses();

      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log(data.responseBody);
          setCourses(() => data.responseBody);
        }
      }
    };

    fetchCourses();

    // if (courseId && courses.length > 0) {
    // console.log("Value  ", parseInt(courseId));
    // setSelectedCourse(() =>
    //   courses.find((item) => item.id === parseInt(courseId))
    // );
    // console.log(
    //   "TPP  ",
    //   courses.find((item) => item.id === parseInt(courseId))
    // );
    // }
  }, []);

  useEffect(() => {
    if (courseId && courses.length > 0) {
      const selectedRole = courses.find(
        (item) => item.id === parseInt(courseId)
      );
      setSelectedCourse(selectedRole);
    }
  }, [courseId, courses]);

  return (
    <div
      onContextMenu={handleContextMenu}
      style={{ display: "flex", justifyContent: "center", userSelect: "none" }}
    >
      <Grid
        container
        direction={"column"}
        sx={{
          width: "40%",
          justifyContent: "center",
          boxShadow: 5,
          gap: 2,
          m: 2,
          p: 5,
          border: 1,
          backgroundColor: "#F5FEFD",
          "@media screen and (max-width: 1250px)": {
            width: "60%",
          },
          "@media screen and (max-width: 750px)": {
            width: "80%",
          },
          "@media screen and (max-width: 500px)": {
            width: "100%",
            margin: "1px",
            padding: "10px",
          },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            fontFamily: "inherit",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          Registration Form
        </Typography>

        <Grid sx={{ display: "flex", justifyContent: "right" }}>
          <Tooltip title="Read Instructions">
            <Button
              sx={{ fontFamily: "inherit", textTransform: "capitalize", py: 0 }}
              onClick={() => setOpen(true)}
            >
              Read Instructions
            </Button>
          </Tooltip>
        </Grid>

        <Grid item sx={sectionGridStyle}>
          <Autocomplete
            sx={{ width: "100%" }}
            id=""
            disableClearable
            readOnly={courseId || !isRegister}
            {...courseProps}
            value={selectedCourse}
            onChange={(event, value) => {
              setSelectedCourse(value);
              console.log(selectedCourse);
            }}
            // onBlur={validateGender}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label="Course Name"
                variant="standard"
                // error={Boolean(genderError)}
                // helperText={genderError}
              />
            )}
            renderOption={(props, option) => (
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "inherit",
                }}
                {...props}
              >
                {option.name +
                  " (" +
                  option.duration +
                  (option.duration === "1" ? " month)" : " months)")}
              </Typography>
            )}
          />
        </Grid>

        <Grid item sx={sectionGridStyle}>
          <Typography variant="body1" sx={sectionHeadingStyle}>
            Personal Information
          </Typography>

          <Box sx={sectionContentStyle}>
            <CustomTextField
              id=""
              required
              label="First Name"
              variant="standard"
              value={firstName}
              onChange={handleFirstNameChange}
              error={Boolean(firstNameError)}
              helperText={firstNameError}
              onBlur={validateFirstName}
            />

            <CustomTextField
              id=""
              required
              label="Last Name"
              variant="standard"
              value={lastName}
              onChange={handleLastNameChange}
              error={Boolean(lastNameError)}
              helperText={lastNameError}
              onBlur={validateLastName}
            />
            <CustomTextField
              id=""
              required
              label="Email ID"
              variant="standard"
              value={email}
              onChange={handleEmailChange}
              error={Boolean(emailError)}
              helperText={emailError}
              onBlur={validateEmail}
            />

            <CustomTextField
              id=""
              required
              label="Phone No."
              variant="standard"
              value={phoneNo}
              onChange={handlePhoneNochange}
              error={Boolean(phoneNoError)}
              helperText={phoneNoError}
              onBlur={validatePhoneNo}
            />

            <CustomTextField
              id=""
              label="Alternate Phone No."
              variant="standard"
              value={altPhoneNo}
              onChange={handleAltPhoneNochange}
            />

            <Autocomplete
              sx={{ width: "100%" }}
              id=""
              required
              disableClearable
              {...genderProps}
              value={gender}
              onChange={handleGenderChange}
              onBlur={validateGender}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Gender"
                  variant="standard"
                  error={Boolean(genderError)}
                  helperText={genderError}
                />
              )}
              renderOption={(props, option) => (
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option}
                </Typography>
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                id=""
                format="DD-MM-YYYY"
                value={dob}
                label="Date of Birth"
                onChange={handleDobChange}
                slotProps={{
                  textField: {
                    required: true,
                    variant: "standard",
                    helperText: dobError,
                    error: Boolean(dobError),
                    onBlur: validateDob,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </Grid>

        <Grid item sx={sectionGridStyle}>
          <Typography variant="body1" sx={sectionHeadingStyle}>
            Academics
          </Typography>

          <Box sx={sectionContentStyle}>
            <Autocomplete
              id=""
              required
              disableClearable
              {...qualificationProps}
              value={qualification}
              onChange={handleQualificationChange}
              onBlur={validateQualification}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Highest Qualification"
                  variant="standard"
                  error={Boolean(qualificationError)}
                  helperText={qualificationError}
                />
              )}
              renderOption={(props, option) => (
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option}
                </Typography>
              )}
            />
            <Autocomplete
              id=""
              sx={{ display: qualification ? "" : "none" }}
              required
              disableClearable
              {...degreeProps}
              value={degree}
              onChange={handleDegreeChange}
              onBlur={validateDegree}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Degree"
                  variant="standard"
                  error={Boolean(degreeError)}
                  helperText={degreeError}
                />
              )}
              renderOption={(props, option) => (
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option}
                </Typography>
              )}
            />

            <CustomTextField
              id=""
              required
              label="Enter your Degree"
              variant="standard"
              value={otherDegree}
              onChange={handleOtherDegreeChange}
              error={Boolean(otherDegreeError)}
              helperText={otherDegreeError}
              onBlur={validateOtherDegree}
              sx={{ display: degree === "Other" ? "" : "none" }}
            />

            <CustomTextField
              id=""
              required
              label="College Name"
              variant="standard"
              value={collegeName}
              onChange={handleCollegeNameChange}
              error={Boolean(collegeNameError)}
              helperText={collegeNameError}
              onBlur={validateCollegeName}
              sx={{ display: qualification ? "" : "none" }}
            />

            <Autocomplete
              id=""
              sx={{ display: qualification ? "" : "none" }}
              required
              disableClearable
              {...branchProps}
              value={branch}
              onChange={handleBranchChange}
              onBlur={validateBranch}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Branch"
                  variant="standard"
                  error={Boolean(branchError)}
                  helperText={branchError}
                />
              )}
              renderOption={(props, option) => (
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option}
                </Typography>
              )}
            />

            <CustomTextField
              id=""
              required
              label="Enter your branch"
              variant="standard"
              value={otherBranch}
              onChange={handleOtherBranchChange}
              error={Boolean(otherBranchError)}
              helperText={otherBranchError}
              onBlur={validateOtherBranch}
              sx={{ display: branch === "Other" ? "" : "none" }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                id=""
                sx={{ display: qualification ? "" : "none" }}
                views={["year"]}
                value={yop}
                label="Year of Completion"
                onChange={handleYopChange}
                slotProps={{
                  textField: {
                    required: true,
                    variant: "standard",
                    helperText: yopError,
                    error: Boolean(yopError),
                    onBlur: validateYop,
                  },
                }}
              />
            </LocalizationProvider>

            <CustomTextField
              id=""
              required
              label="CGPA"
              variant="standard"
              value={cgpa}
              onChange={handleCgpaChange}
              error={Boolean(cgpaError)}
              helperText={cgpaError}
              onBlur={validateCgpa}
              sx={{ display: qualification ? "" : "none" }}
            />
          </Box>
        </Grid>

        <Grid item sx={sectionGridStyle}>
          <Typography variant="body1" sx={sectionHeadingStyle}>
            Address Information
          </Typography>

          <Box sx={sectionContentStyle}>
            <CustomTextField
              id=""
              required
              label="Address Line 1"
              variant="standard"
              value={address1}
              onChange={handleAddress1Change}
              error={Boolean(address1Error)}
              helperText={address1Error}
              onBlur={validateAddress1}
            />

            <CustomTextField
              id=""
              label="Address Line 2"
              variant="standard"
              value={address2}
              onChange={handleAddress2Change}
            />

            <CustomTextField
              id=""
              required
              label="Landmark"
              variant="standard"
              value={landmark}
              onChange={handleLandmarkChange}
              error={Boolean(landmarkError)}
              helperText={landmarkError}
              onBlur={validateLandmark}
            />

            <CustomTextField
              id=""
              required
              label="City"
              variant="standard"
              value={city}
              onChange={handleCityChange}
              error={Boolean(cityError)}
              helperText={cityError}
              onBlur={validateCity}
            />

            <CustomTextField
              id=""
              required
              label="State"
              variant="standard"
              value={state}
              onChange={handleStateChange}
              error={Boolean(stateError)}
              helperText={stateError}
              onBlur={validateState}
            />

            <CustomTextField
              id=""
              required
              label="Pincode"
              variant="standard"
              value={pincode}
              onChange={handlePincodeChange}
              error={Boolean(pincodeError)}
              helperText={pincodeError}
              onBlur={validatePincode}
            />
          </Box>
        </Grid>

        <Grid item sx={sectionGridStyle} display={isRegister ? "" : "none"}>
          <Typography variant="body1" sx={sectionHeadingStyle}>
            Additional Information
          </Typography>

          <Box sx={sectionContentStyle}>
            <CustomTextField
              id=""
              multiline
              maxRows={4}
              required
              label="Skills"
              variant="standard"
              value={skills}
              onChange={handleSkillsChange}
              error={Boolean(skillsError)}
              helperText={skillsError}
              onBlur={validateSkills}
            />

            <CustomFileInput
              variant="standard"
              required
              sx={{ display: isRegister ? "" : "none" }}
              id="payment_receipt"
              value={paymentReceiptFile}
              onChange={handleChangePaymentReceipt}
              label="Upload you Payment Receipt"
              error={Boolean(paymentReceiptFileError)}
              helperText={paymentReceiptFileError}
              onBlur={validatePaymentReceiptFile}
            />
          </Box>
        </Grid>

        <Grid
          item
          sx={{
            display: isRegister ? "flex" : "none",
            alignItems: "flex-start",
            justifyContent: "left",
          }}
        >
          <FormControlLabel
            sx={{ mx: 1 }}
            control={
              <Checkbox
                checked={agreementChecked}
                onChange={handleAgreementCheckboxChange}
                sx={{ p: 0 }}
              />
            }
          />

          <Typography variant="body2" sx={{ fontFamily: "inherit" }}>
            I consent to LueurTech Software Solution's use of my personal
            information, including my name, email, phone number, and address,
            for the purpose of contacting me in the future. I agree to allow
            LueurTech Software Solutions to utilize this information within the
            limits specified.
          </Typography>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={isRegister && !agreementChecked}
            variant="contained"
            onClick={handleSubmit}
            sx={{ backgroundColor: "teal" }}
          >
            Submit
          </Button>
        </Box>
      </Grid>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Form_Instructions onClick={() => setOpen(false)} />
      </Modal>
    </div>
  );
}

export default Trainee;
