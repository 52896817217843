import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomDatePicker from "../../GenericComponents/CustomDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomTextField from "../../GenericComponents/CustomTextField";
import trainerServices from "../../Services/TrainerServices";
import jobRoleServices from "../../Services/JobRoleServices";
import { useParams } from "react-router-dom";

const sectionGridStyle = {
  borderRadius: 2,
  p: 2,
  boxShadow: 1,
  backgroundColor: "#F5F5F5",
};

const sectionHeadingStyle = {
  fontFamily: "inherit",
  fontWeight: "bold",
  textTransform: "uppercase",
  mx: 2,
};

const sectionContentStyle = {
  display: "grid",
  gap: 2,
  mx: 2,
  mt: 2,
  mb: 1,
  p: 3,
  backgroundColor: "white",
  borderRadius: 2,
};

function Trainer() {
  // Form field states
  const [resumeFile, setResumeFile] = useState(null);
  const [firstName, setFirstName] = useState(""); // Personal Information
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [altPhoneNo, setAltPhoneNo] = useState("");
  const [gender, setGender] = useState(null);
  const [dob, setDob] = useState(null);

  const [currentAddress1, setCurrentAddress1] = useState("");
  const [currentAddress2, setCurrentAddress2] = useState("");
  const [currentLandmark, setCurrentLandmark] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [currentState, setCurrentState] = useState("");
  const [currentPincode, setCurrentPincode] = useState("");
  const [addressChecked, setAddressChecked] = useState(false);
  const [permanentAddress1, setPermanentAddress1] = useState("");
  const [permanentAddress2, setPermanentAddress2] = useState("");
  const [permanentLandmark, setPermanentLandmark] = useState("");
  const [permanentCity, setPermanentCity] = useState("");
  const [permanentState, setPermanentState] = useState("");
  const [permanentPincode, setPermanentPincode] = useState("");

  const [qualification, setQualification] = useState(null);
  const [degree, setDegree] = useState(null);
  const [otherDegree, setOtherDegree] = useState("");
  const [branch, setBranch] = useState(null);
  const [otherBranch, setOtherBranch] = useState("");
  const [yop, setYop] = useState(null);
  const [cgpa, setCgpa] = useState("");
  const [collegeName, setCollegeName] = useState("");

  const [skills, setSkills] = useState("");
  const [yearsOfExp, setYearsOfExp] = useState("");
  const [dateOfAvailability, setDateOfAvailability] = useState(null);

  const [agreementChecked, setAgreementChecked] = useState(false);

  // Form Field error states
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [dobError, setDobError] = useState("");

  const [currentAddress1Error, setCurrentAddress1Error] = useState("");
  const [currentLandmarkError, setCurrentLandmarkError] = useState("");
  const [currentCityError, setCurrentCityError] = useState("");
  const [currentStateError, setCurrentStateError] = useState("");
  const [currentPincodeError, setCurrentPincodeError] = useState("");
  const [permanentAddress1Error, setPermanentAddress1Error] = useState("");
  const [permanentLandmarkError, setPermanentLandmarkError] = useState("");
  const [permanentCityError, setPermanentCityError] = useState("");
  const [permanentStateError, setPermanentStateError] = useState("");
  const [permanentPincodeError, setPermanentPincodeError] = useState("");

  const [qualificationError, setQualificationError] = useState("");
  const [degreeError, setDegreeError] = useState("");
  const [otherDegreeError, setOtherDegreeError] = useState("");
  const [branchError, setBranchError] = useState("");
  const [otherBranchError, setOtherBranchError] = useState("");
  const [yopError, setYopError] = useState("");
  const [cgpaError, setCgpaError] = useState("");
  const [collegeNameError, setCollegeNameError] = useState("");

  const [skillsError, setSkillsError] = useState("");
  const [yearsOfExpError, setYearsOfExpError] = useState("");
  const [dateOfAvailabilityError, setDateOfAvailabilityError] = useState("");

  const [jobRoles, setJobRoles] = useState([]);
  const [selectedJobRole, setSelectedJobRole] = useState(null);

  // Autocomplete props

  const jobRoleProps = {
    options: jobRoles,
    getOptionLabel: (option) => option.role,
  };

  const genderList = ["Male", "Female", "Others"];
  const genderProps = {
    options: genderList,
  };

  const qualificationsList = ["Post Graduate/Dual Degree", "Graduate"];
  const qualificationProps = {
    options: qualificationsList,
  };

  const mastersDegreeList = [
    "Dual Degree M.E",
    "Dual Degree M.Tech",
    "Dual degree M.Sc",
    "Integrated MBA",
    "M Sc (IT or CSC)",
    "M.A (Master of Arts)",
    "M.B.A(Masters in Business Administration) - Tier 2 / Tier3 only",
    "M.C.A(Master of Computer Application)",
    "M.C.M(Master of Computer Management)",
    "M.Com (Master Of Commerce)",
    "M.E.(Master of Engineering)",
    "M.FA (Master of Fine Arts)",
    "M.Pharm (Master of Pharmacy)",
    "MS / M.Sc(Master of Science)",
    "M.Tech.(Master of Technology)",
    "MMS/ PGDM NonPremium Institute(Tier 2 / Tier 3)",
    "Master of Design",
    "PGDCA(Post Graduate Diploma in Computer Application)",
    "Post Graduate Diploma",
    "Tech PG - Relevant (M Tech/ ME/ MS/ PGDCM/ IIS)",
    "Other",
  ];

  const bachelorsDegreeList = [
    "B.A. (Bachelor of Arts)",
    "B.ActSc (Bachelors in Actuarial Science)",
    "B.Agri & RD (Bachelors in Agriculture & Rural Development)",
    "B.A.F (Bachelors in Accounting & Finance)",
    "B.B.I (Bachelors in Banking & Insurance)",
    "B.B.S (Bachelor in Business Studies)",
    "B.C.A (Bachelor of Computer Application)",
    "B.Com. (Bachelor of Commerce)",
    "B.Design (Bachelor of Design)",
    "B.E. (Bachelor of Engineering)",
    "B.Ed. (Bachelor of Education)",
    "B.F.M (Bachelors of Financial Markets)",
    "B.FA (Bachelor of Fine Arts)",
    "B.L.I.Sc. (Bachelors of Library & Information Science)",
    "B.M.M (Bachelors of Mass Media)",
    "BMS (Bachelor of Management Studie)",
    "B.Pharma (Bachelor of Pharmacy)",
    "B.Plan (Bachelor of Planning)",
    "B.PSc (Bachelors in Political Sciences)",
    "B.S.Micr (Bachelor in Science Microbiology)",
    "B.Sc (Bachelor of Science)",
    "B.Tech. (Bachelor of Technology)",
    "B.Voc (Bachelor of Vocation)",
    "BBA (Bachelor of Business Administration)",
    "BBiot (Bachelor of Biotechnology)",
    "Other",
  ];
  const degreeProps = {
    options:
      qualification === "Post Graduate/Dual Degree"
        ? mastersDegreeList
        : bachelorsDegreeList,
  };

  const branchList = ["CSE", "ISE", "Mech.", "Civil", "Other"];
  const branchProps = {
    options: branchList,
  };

  const availabilityList = ["Immediate", "Within 30 days", "More than 30 days"];

  const availabilityProps = {
    options: availabilityList,
  };

  // Form Field handlers
  const handleFileChange = (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      setResumeFile(acceptedFiles[0]);
    } else {
      console.log("ERROR");
    }
  };

  // Prevent right-click context menu

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const handleFirstNameChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setFirstNameError("");
    setFirstName(newValue);
  };

  const handleLastNameChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setLastNameError("");
    setLastName(newValue);
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9@._]/g, ""); // Only allow alphanumeric and Special  characters
    setEmailError("");
    setEmail(newValue);
  };

  const handlePhoneNochange = (event) => {
    const { value } = event.target;
    // Only allow numeric characters and limit to 10 characters
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 10);
    setPhoneNoError("");
    setPhoneNo(newValue);
  };

  const handleAltPhoneNochange = (event) => {
    const { value } = event.target;
    // Only allow numeric characters and limit to 10 characters
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 10);

    setAltPhoneNo(newValue);
  };

  const handleGenderChange = (event, value) => {
    setGenderError("");
    setGender(value);
  };

  const handleDobChange = (value) => {
    setDobError("");
    setDob(value);
  };

  const handleQualificationChange = (event, value) => {
    setQualificationError("");
    setQualification(value);
  };

  const handleDegreeChange = (event, value) => {
    setDegreeError("");
    setDegree(value);
  };

  const handleOtherDegreeChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9._,-]/g, ""); // Only allow alphanumeric and Special  characters
    setOtherDegreeError("");
    setOtherDegree(newValue);
  };

  const handleBranchChange = (event, value) => {
    setBranchError("");
    setBranch(value);
  };

  const handleOtherBranchChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9._,-]/g, ""); // Only allow alphanumeric and Special  characters
    setOtherBranchError("");
    setOtherBranch(newValue);
  };

  const handleYopChange = (value) => {
    setYopError("");
    setYop(value);
  };

  const handleCgpaChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^0-9%.]/g, "").slice(0, 5);
    setCgpaError("");
    setCgpa(newValue);
  };

  const handleCollegeNameChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setCollegeNameError("");
    setCollegeName(newValue);
  };
  const handleCurrentAddress1Change = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9._,\s-]/g, ""); // \s represents space character
    setCurrentAddress1Error("");
    setCurrentAddress1(newValue);
  };

  const handleCurrentAddress2Change = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9._,\s-]/g, ""); // \s represents space character
    setCurrentAddress2(newValue);
  };

  const handleCurrentLandmarkChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9._,\s-]/g, ""); // \s represents space character
    setCurrentLandmarkError("");
    setCurrentLandmark(newValue);
  };

  const handleCurrentCityChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z ]/g, ""); // Only allow alphanumeric and Special  characters
    setCurrentCityError("");
    setCurrentCity(newValue);
  };

  const handleCurrentStateChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z ]/g, ""); // Only allow alphanumeric and Special  characters
    setCurrentStateError("");
    setCurrentState(newValue);
  };

  const handleCurrentPincodeChange = (event) => {
    const { value } = event.target;
    // Only allow numeric characters and limit to 6 characters
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 6);
    setCurrentPincodeError("");
    setCurrentPincode(newValue);
  };

  const handlePermanentAddress1Change = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9._,\s-]/g, ""); // \s represents space character
    setPermanentAddress1Error("");
    setPermanentAddress1(newValue);
  };

  const handlePermanentAddress2Change = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9._,\s-]/g, ""); // \s represents space character
    setPermanentAddress2(newValue);
  };

  const handlePermanentLandmarkChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z0-9._,\s-]/g, ""); // \s represents space character
    setPermanentLandmarkError("");
    setPermanentLandmark(newValue);
  };

  const handlePermanentCityChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z ]/g, ""); // Only allow alphanumeric and Special  characters
    setPermanentCityError("");
    setPermanentCity(newValue);
  };

  const handlePermanentStateChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z ]/g, ""); // Only allow alphanumeric and Special  characters
    setPermanentStateError("");
    setPermanentState(newValue);
  };

  const handlePermanentPincodeChange = (event) => {
    const { value } = event.target;
    // Only allow numeric characters and limit to 6 characters
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 6);
    setPermanentPincodeError("");
    setPermanentPincode(newValue);
  };
  const handleSkillsChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^A-Za-z, ]/g, ""); // Only allow alphanumeric and Special  characters
    setSkillsError("");
    setSkills(newValue);
  };

  const handleYearsOfExpChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/[^0-9.]/g, "").slice(0, 2); // Only allow alphanumeric and Special  characters

    setYearsOfExpError("");
    setYearsOfExp(newValue);
  };

  const handleDateOfAvailability = (event, value) => {
    setDateOfAvailabilityError("");
    setDateOfAvailability(value);
  };

  const handleAddressCheckboxChange = (event) => {
    setAddressChecked(event.target.checked);

    if (event.target.checked) {
      setPermanentAddress1(currentAddress1);
      setPermanentAddress2(currentAddress2);
      setPermanentLandmark(currentLandmark);
      setPermanentCity(currentCity);
      setPermanentState(currentState);
      setPermanentPincode(currentPincode);
    } else {
      setPermanentAddress1("");
      setPermanentAddress2("");
      setPermanentLandmark("");
      setPermanentCity("");
      setPermanentState("");
      setPermanentPincode("");
    }
  };

  const handleAgreementCheckboxChange = (event) => {
    setAgreementChecked(event.target.checked);
  };

  // Form Field error handlers
  const validateFirstName = () => {
    setFirstNameError(firstName ? "" : "This field is required");
  };

  const validateLastName = () => {
    setLastNameError(lastName ? "" : "This field is required");
  };

  const validateEmail = () => {
    setEmailError(email ? "" : "This field is required");
  };

  const validatePhoneNo = () => {
    setPhoneNoError(phoneNo ? "" : "This field is required");
  };

  const validateGender = () => {
    setGenderError(gender ? "" : "This field is required");
  };

  const validateDob = () => {
    setDobError(dob != null ? "" : "This field is required");
  };

  const validateCurrentAddress1 = () => {
    setCurrentAddress1Error(currentAddress1 ? "" : "This field is required");
  };

  const validateCurrentLandmark = () => {
    setCurrentLandmarkError(currentLandmark ? "" : "This field is required");
  };

  const validateCurrentCity = () => {
    setCurrentCityError(currentCity ? "" : "This field is required");
  };

  const validateCurrentState = () => {
    setCurrentStateError(currentState ? "" : "This field is required");
  };

  const validateCurrentPincode = () => {
    setPermanentPincodeError(currentPincode ? "" : "This field is required");
  };

  const validatePermanentAddress1 = () => {
    setPermanentAddress1Error(
      permanentAddress1 ? "" : "This field is required"
    );
  };

  const validatePermanentLandmark = () => {
    setPermanentLandmarkError(
      permanentLandmark ? "" : "This field is required"
    );
  };

  const validatePermanentCity = () => {
    setPermanentCityError(permanentCity ? "" : "This field is required");
  };

  const validatePermanentState = () => {
    setPermanentStateError(permanentState ? "" : "This field is required");
  };

  const validatePermanentPincode = () => {
    setPermanentPincodeError(permanentPincode ? "" : "This field is required");
  };

  const validateQualification = () => {
    setQualificationError(qualification ? "" : "This field is required");
  };

  const validateDegree = () => {
    setDegreeError(degree ? "" : "This field is required");
  };

  const validateOtherDegree = () => {
    setOtherDegreeError(otherDegree ? "" : "This field is required");
  };

  const validateBranch = () => {
    setBranchError(branch ? "" : "This field is required");
  };

  const validateOtherBranch = () => {
    setOtherBranchError(otherBranch ? "" : "This field is required");
  };

  const validateYop = () => {
    setYopError(yop != null ? "" : "This field is required");
  };

  const validateCgpa = () => {
    setCgpaError(cgpa ? "" : "This field is required");
  };

  const validateCollegeName = () => {
    setCollegeNameError(collegeName ? "" : "This field is required");
  };

  const validateSkills = () => {
    setSkillsError(skills ? "" : "This field is required");
  };

  const validateYearsOfExp = () => {
    setYearsOfExpError(yearsOfExp ? "" : "This field is required");
  };

  const validateDateOfAvailability = () => {
    setDateOfAvailabilityError(
      dateOfAvailability ? "" : "This field is required"
    );
  };

  const validateFormFields = () => {
    validateFirstName();
    validateLastName();
    validateEmail();
    validatePhoneNo();
    validateGender();
    validateDob();
    validateCurrentAddress1();
    validateCurrentLandmark();
    validateCurrentCity();
    validateCurrentState();
    validateCurrentPincode();
    validatePermanentAddress1();
    validatePermanentLandmark();
    validatePermanentCity();
    validatePermanentState();
    validatePermanentPincode();
    validateQualification();
    validateDegree();
    validateOtherDegree();
    validateBranch();
    validateOtherBranch();
    validateYop();
    validateCgpa();
    validateCollegeName();
    validateSkills();
    validateYearsOfExp();
    validateDateOfAvailability();

    const isFormValid =
      resumeFile &&
      firstName &&
      lastName &&
      email &&
      phoneNo &&
      gender &&
      dob != null &&
      currentAddress1 &&
      currentLandmark &&
      currentCity &&
      currentState &&
      currentPincode &&
      permanentAddress1 &&
      permanentLandmark &&
      permanentCity &&
      permanentState &&
      permanentPincode &&
      qualification &&
      (degree === "Other" ? otherDegree : degree) &&
      (branch === "Other" ? otherBranch : branch) &&
      yop != null &&
      cgpa &&
      collegeName &&
      skills &&
      yearsOfExp &&
      dateOfAvailability;
    return isFormValid;
  };

  const handleSubmit = async (event) => {
    if (validateFormFields()) {
      const files = [resumeFile];

      const timestamp = new Date().toISOString();
      const formattedDob = dob.format("DD-MM-YYYY");
      const formattedYop = yop.format("YYYY");
      const jsonData = {
        jobRoleId: selectedJobRole.id,
        name: firstName + " " + lastName,
        email: email,
        phoneNo: altPhoneNo ? phoneNo + " / " + altPhoneNo : phoneNo,
        gender: gender,
        dob: formattedDob,
        currentAddress: currentAddress2
          ? currentAddress1 + ", " + currentAddress2
          : currentAddress1,
        currentState: currentState,
        currentLandmark: currentLandmark,
        currentCity: currentCity,
        currentPinCode: currentPincode,
        permanentAddress: permanentAddress2
          ? permanentAddress1 + ", " + permanentAddress2
          : permanentAddress1,
        permanentState: permanentState,
        permanentLandmark: permanentLandmark,
        permanentCity: permanentCity,
        permanentPinCode: permanentPincode,
        degree: degree !== "Other" ? degree : otherDegree,
        branch: branch !== "Other" ? branch : otherBranch,
        yop: formattedYop,
        cgpa: cgpa,
        collegeName: collegeName,
        skills: skills,
        yearsOfExperience: yearsOfExp,
        dateOfAvailability: dateOfAvailability,
        timestamp: timestamp,
      };
      console.log(jsonData);
      let data = await trainerServices.AddTrainer(files, jsonData);

      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log("Trainer Added Successfully. ", data.responseBody);
          // resetValues();
          window.location.replace("/success");
        } else {
          console.log("Invalid trainer details.");
        }
      }
    }
  };

  // const resetValues = () => {
  //   setResumeFile(null);
  //   setFirstName("");
  //   setLastName("");
  //   setEmail("");
  //   setPhoneNo("");
  //   setGender(null);
  //   setDob(null);
  //   setCurrentAddress1("");
  //   setCurrentLandmark("");
  //   setCurrentCity("");
  //   setCurrentState("");
  //   setCurrentPincode("");
  //   setPermanentAddress1("");
  //   setPermanentLandmark("");
  //   setPermanentCity("");
  //   setPermanentState("");
  //   setPermanentPincode("");
  //   setDegree(null);
  //   setOtherDegree("");
  //   setBranch(null);
  //   setOtherBranch("");
  //   setYop(null);
  //   setCgpa("");
  //   setCollegeName("");
  //   setSkills("");
  //   setYearsOfExp("");
  //   setDateOfAvailability(null);
  // };

  let { jobId } = useParams();

  useEffect(() => {
    const fetchJobRoles = async () => {
      let data = await jobRoleServices.getJobRoles();

      if (data === false) {
        console.log("Something wnt wrong.");
      } else {
        if (data.code === 200) {
          // console.log(data.responseBody);
          setJobRoles(() => data.responseBody);
        }
      }
    };

    fetchJobRoles();

    // if (jobId && jobRoles.length > 0) {
    // console.log("Value  ", parseInt(jobId));
    // setSelectedJobRole(() =>
    //   jobRoles.find((item) => item.id === parseInt(jobId))
    // );
    // console.log(
    //   "TPP  ",
    //   jobRoles.find((item) => item.id === parseInt(jobId))
    // );
    // }
  }, []);

  useEffect(() => {
    if (jobId && jobRoles.length > 0) {
      const selectedRole = jobRoles.find((item) => item.id === parseInt(jobId));
      setSelectedJobRole(selectedRole);
    }
  }, [jobId, jobRoles]);

  return (
    <div
      onContextMenu={handleContextMenu}
      style={{ display: "flex", justifyContent: "center", userSelect: "none" }}
    >
      <Grid
        container
        direction={"column"}
        sx={{
          width: "40%",
          justifyContent: "center",
          boxShadow: 5,
          gap: 2,
          m: 2,
          p: 5,
          border: 1,
          backgroundColor: "#F5FEFD",
          "@media screen and (max-width: 1250px)": {
            width: "60%",
          },
          "@media screen and (max-width: 750px)": {
            width: "80%",
          },
          "@media screen and (max-width: 500px)": {
            width: "100%",
            margin: "1px",
            padding: "10px",
          },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            fontFamily: "inherit",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          Application Form
        </Typography>

        <Grid item sx={sectionGridStyle}>
          <Typography variant="body1" sx={sectionHeadingStyle}>
            Upload Resume/CV
          </Typography>
          <Box sx={sectionContentStyle}>
            <Dropzone
              onDrop={handleFileChange}
              accept={".pdf"}
              maxSize={2 * 1024 * 1024}
            >
              {({ getRootProps, getInputProps }) => (
                <Grid
                  item
                  {...getRootProps()}
                  sx={{
                    display: "block",
                    cursor: "pointer",
                    border: 1,
                    borderStyle: "dotted",
                    textAlign: "center",
                    alignSelf: "center",
                    p: 1,
                  }}
                >
                  <CloudUploadIcon fontSize="large" />
                  <Typography>
                    Drag and drop your resume here or click to select a file
                    locally
                  </Typography>
                  <Typography>Accepted file type: PDF</Typography>
                  <Typography>Max file size: 2MB</Typography>
                  <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                    {resumeFile ? "Selected File: " + resumeFile.name : ""}
                  </Typography>
                  <input {...getInputProps()} />
                </Grid>
              )}
            </Dropzone>
          </Box>
        </Grid>

        <Grid item sx={sectionGridStyle}>
          <Autocomplete
            sx={{ width: "100%" }}
            id=""
            disableClearable
            readOnly={jobId}
            {...jobRoleProps}
            value={selectedJobRole}
            onChange={(event, value) => {
              setSelectedJobRole(value);
              console.log(selectedJobRole);
            }}
            // onBlur={validateGender}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label="Job Role"
                variant="standard"
                // error={Boolean(genderError)}
                // helperText={genderError}
              />
            )}
            renderOption={(props, option) => (
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "inherit",
                }}
                {...props}
              >
                {option.role}
              </Typography>
            )}
          />
        </Grid>

        <Grid item sx={sectionGridStyle}>
          <Typography variant="body1" sx={sectionHeadingStyle}>
            Personal Information
          </Typography>

          <Box sx={sectionContentStyle}>
            <CustomTextField
              id=""
              required
              label="First Name"
              variant="standard"
              value={firstName}
              onChange={handleFirstNameChange}
              error={Boolean(firstNameError)}
              helperText={firstNameError}
              onBlur={validateFirstName}
            />

            <CustomTextField
              id=""
              required
              label="Last Name"
              variant="standard"
              value={lastName}
              onChange={handleLastNameChange}
              error={Boolean(lastNameError)}
              helperText={lastNameError}
              onBlur={validateLastName}
            />
            <CustomTextField
              id=""
              required
              label="Email ID"
              variant="standard"
              value={email}
              onChange={handleEmailChange}
              error={Boolean(emailError)}
              helperText={emailError}
              onBlur={validateEmail}
            />

            <CustomTextField
              id=""
              required
              label="Phone No."
              variant="standard"
              value={phoneNo}
              onChange={handlePhoneNochange}
              error={Boolean(phoneNoError)}
              helperText={phoneNoError}
              onBlur={validatePhoneNo}
            />

            <CustomTextField
              id=""
              label="Alternate Phone No."
              variant="standard"
              value={altPhoneNo}
              onChange={handleAltPhoneNochange}
            />

            <Autocomplete
              sx={{ width: "100%" }}
              id=""
              required
              disableClearable
              {...genderProps}
              value={gender}
              onChange={handleGenderChange}
              onBlur={validateGender}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Gender"
                  variant="standard"
                  error={Boolean(genderError)}
                  helperText={genderError}
                />
              )}
              renderOption={(props, option) => (
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option}
                </Typography>
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                id=""
                format="DD-MM-YYYY"
                value={dob}
                label="Date of Birth"
                onChange={handleDobChange}
                slotProps={{
                  textField: {
                    required: true,
                    variant: "standard",
                    helperText: dobError,
                    error: Boolean(dobError),
                    onBlur: validateDob,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </Grid>

        <Grid item sx={sectionGridStyle}>
          <Typography variant="body1" sx={sectionHeadingStyle}>
            Academics
          </Typography>

          <Box sx={sectionContentStyle}>
            <Autocomplete
              id=""
              required
              disableClearable
              {...qualificationProps}
              value={qualification}
              onChange={handleQualificationChange}
              onBlur={validateQualification}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Highest Qualification"
                  variant="standard"
                  error={Boolean(qualificationError)}
                  helperText={qualificationError}
                />
              )}
              renderOption={(props, option) => (
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option}
                </Typography>
              )}
            />
            <Autocomplete
              id=""
              sx={{ display: qualification ? "" : "none" }}
              required
              disableClearable
              {...degreeProps}
              value={degree}
              onChange={handleDegreeChange}
              onBlur={validateDegree}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Degree"
                  variant="standard"
                  error={Boolean(degreeError)}
                  helperText={degreeError}
                />
              )}
              renderOption={(props, option) => (
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option}
                </Typography>
              )}
            />

            <CustomTextField
              id=""
              required
              label="Enter your Degree"
              variant="standard"
              value={otherDegree}
              onChange={handleOtherDegreeChange}
              error={Boolean(otherDegreeError)}
              helperText={otherDegreeError}
              onBlur={validateOtherDegree}
              sx={{ display: degree === "Other" ? "" : "none" }}
            />

            <CustomTextField
              id=""
              required
              label="College Name"
              variant="standard"
              value={collegeName}
              onChange={handleCollegeNameChange}
              error={Boolean(collegeNameError)}
              helperText={collegeNameError}
              onBlur={validateCollegeName}
              sx={{ display: qualification ? "" : "none" }}
            />

            <Autocomplete
              id=""
              sx={{ display: qualification ? "" : "none" }}
              required
              disableClearable
              {...branchProps}
              value={branch}
              onChange={handleBranchChange}
              onBlur={validateBranch}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Branch"
                  variant="standard"
                  error={Boolean(branchError)}
                  helperText={branchError}
                />
              )}
              renderOption={(props, option) => (
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option}
                </Typography>
              )}
            />

            <CustomTextField
              id=""
              required
              label="Enter your branch"
              variant="standard"
              value={otherBranch}
              onChange={handleOtherBranchChange}
              error={Boolean(otherBranchError)}
              helperText={otherBranchError}
              onBlur={validateOtherBranch}
              sx={{ display: branch === "Other" ? "" : "none" }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                id=""
                sx={{ display: qualification ? "" : "none" }}
                views={["year"]}
                value={yop}
                label="Year of Completion"
                onChange={handleYopChange}
                slotProps={{
                  textField: {
                    required: true,
                    variant: "standard",
                    helperText: yopError,
                    error: Boolean(yopError),
                    onBlur: validateYop,
                  },
                }}
              />
            </LocalizationProvider>

            <CustomTextField
              id=""
              required
              label="CGPA/Percentage"
              variant="standard"
              value={cgpa}
              onChange={handleCgpaChange}
              error={Boolean(cgpaError)}
              helperText={cgpaError}
              onBlur={validateCgpa}
              sx={{ display: qualification ? "" : "none" }}
            />
          </Box>
        </Grid>

        <Grid item sx={sectionGridStyle}>
          <Typography variant="body1" sx={sectionHeadingStyle}>
            Address Information
          </Typography>

          <Box sx={sectionContentStyle}>
            <Typography
              variant="body1"
              sx={{ fontFamily: "inherit", fontWeight: "bold" }}
            >
              Current Address
            </Typography>
            <CustomTextField
              id=""
              required
              label="Address Line 1"
              variant="standard"
              value={currentAddress1}
              onChange={handleCurrentAddress1Change}
              error={Boolean(currentAddress1Error)}
              helperText={currentAddress1Error}
              onBlur={validateCurrentAddress1}
            />

            <CustomTextField
              id=""
              label="Address Line 2"
              variant="standard"
              value={currentAddress2}
              onChange={handleCurrentAddress2Change}
            />

            <CustomTextField
              id=""
              required
              label="Landmark"
              variant="standard"
              value={currentLandmark}
              onChange={handleCurrentLandmarkChange}
              error={Boolean(currentLandmarkError)}
              helperText={currentLandmarkError}
              onBlur={validateCurrentLandmark}
            />

            <CustomTextField
              id=""
              required
              label="City"
              variant="standard"
              value={currentCity}
              onChange={handleCurrentCityChange}
              error={Boolean(currentCityError)}
              helperText={currentCityError}
              onBlur={validateCurrentCity}
            />

            <CustomTextField
              id=""
              required
              label="State"
              variant="standard"
              value={currentState}
              onChange={handleCurrentStateChange}
              error={Boolean(currentStateError)}
              helperText={currentStateError}
              onBlur={validateCurrentState}
            />

            <CustomTextField
              id=""
              required
              label="Pincode"
              variant="standard"
              value={currentPincode}
              onChange={handleCurrentPincodeChange}
              error={Boolean(currentPincodeError)}
              helperText={currentPincodeError}
              onBlur={validateCurrentPincode}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={addressChecked}
                  disabled={
                    currentAddress1 &&
                    currentLandmark &&
                    currentCity &&
                    currentState &&
                    currentPincode
                      ? false
                      : true
                  }
                  onChange={handleAddressCheckboxChange}
                />
              }
              label="Is your current address same as permanent address"
            />

            <Typography
              variant="body1"
              sx={{ fontFamily: "inherit", fontWeight: "bold" }}
            >
              Permanent Address
            </Typography>

            <CustomTextField
              id=""
              required
              label="Address Line 1"
              variant="standard"
              value={permanentAddress1}
              onChange={handlePermanentAddress1Change}
              error={Boolean(permanentAddress1Error)}
              helperText={permanentAddress1Error}
              onBlur={validatePermanentAddress1}
              InputProps={{ readOnly: addressChecked }}
            />

            <CustomTextField
              id=""
              label="Address Line 2"
              variant="standard"
              value={permanentAddress2}
              onChange={handlePermanentAddress2Change}
              InputProps={{ readOnly: addressChecked }}
            />

            <CustomTextField
              id=""
              required
              label="Landmark"
              variant="standard"
              value={permanentLandmark}
              onChange={handlePermanentLandmarkChange}
              error={Boolean(permanentLandmarkError)}
              helperText={permanentLandmarkError}
              onBlur={validatePermanentLandmark}
              InputProps={{ readOnly: addressChecked }}
            />

            <CustomTextField
              id=""
              required
              label="City"
              variant="standard"
              value={permanentCity}
              onChange={handlePermanentCityChange}
              error={Boolean(permanentCityError)}
              helperText={permanentCityError}
              onBlur={validatePermanentCity}
              InputProps={{ readOnly: addressChecked }}
            />

            <CustomTextField
              id=""
              required
              label="State"
              variant="standard"
              value={permanentState}
              onChange={handlePermanentStateChange}
              error={Boolean(permanentStateError)}
              helperText={permanentStateError}
              onBlur={validatePermanentState}
              InputProps={{ readOnly: addressChecked }}
            />

            <CustomTextField
              id=""
              required
              label="Pincode"
              variant="standard"
              value={permanentPincode}
              onChange={handlePermanentPincodeChange}
              error={Boolean(permanentPincodeError)}
              helperText={permanentPincodeError}
              onBlur={validatePermanentPincode}
              InputProps={{ readOnly: addressChecked }}
            />
          </Box>
        </Grid>

        <Grid item sx={sectionGridStyle}>
          <Typography variant="body1" sx={sectionHeadingStyle}>
            Experience
          </Typography>

          <Box sx={sectionContentStyle}>
            <CustomTextField
              id=""
              required
              label="Years of Experience"
              variant="standard"
              value={yearsOfExp}
              onChange={handleYearsOfExpChange}
              error={Boolean(yearsOfExpError)}
              helperText={yearsOfExpError}
              onBlur={validateYearsOfExp}
            />

            <CustomTextField
              id=""
              multiline
              maxRows={4}
              required
              label="Skills"
              variant="standard"
              value={skills}
              onChange={handleSkillsChange}
              error={Boolean(skillsError)}
              helperText={skillsError}
              onBlur={validateSkills}
            />

            <Autocomplete
              id=""
              required
              disableClearable
              {...availabilityProps}
              value={dateOfAvailability}
              onChange={handleDateOfAvailability}
              onBlur={validateDateOfAvailability}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Available to join"
                  variant="standard"
                  error={Boolean(dateOfAvailabilityError)}
                  helperText={dateOfAvailabilityError}
                />
              )}
              renderOption={(props, option) => (
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option}
                </Typography>
              )}
            />
          </Box>
        </Grid>

        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "left",
          }}
        >
          <FormControlLabel
            sx={{ mx: 1 }}
            control={
              <Checkbox
                checked={agreementChecked}
                onChange={handleAgreementCheckboxChange}
                sx={{ p: 0 }}
              />
            }
          />

          <Typography variant="body2" sx={{ fontFamily: "inherit" }}>
            I consent to LueurTech Software Solution's use of my personal
            information, including my name, email, phone number, and address,
            for the purpose of contacting me in the future. I agree to allow
            LueurTech Software Solutions to utilize this information within the
            limits specified.
          </Typography>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={!agreementChecked}
            variant="contained"
            onClick={handleSubmit}
            sx={{ backgroundColor: "teal" }}
          >
            Submit
          </Button>
        </Box>
      </Grid>
    </div>
  );
}

export default Trainer;
