import axios from "axios";
import { apiEndpoint } from "../config";

let axiosConfig = {
  withCredentials: true,
};

class JobRoleServices {
  async getJobRoles() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/jobRole/list`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }
}

const jobRoleServices = new JobRoleServices();
export default jobRoleServices;
