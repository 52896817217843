import { MuiFileInput } from "mui-file-input";

const { styled } = require("@mui/material");

const CustomFileInput = styled(MuiFileInput)({
  width: "100%",

  "& .MuiInput-root , & .MuiFormHelperText-root , & .MuiInputLabel-root, & .MuiFormHelperText-root":
    { fontSize: 14, fontFamily: "inherit", color: "black" },

  "& .MuiInput-root": { marginTop: 12 },

  "& .MuiFormHelperText-root": {},

  "& .MuiInputAdornment-root": {
    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
    },
  },

  // On Focus
  "&:focus-within ": {
    "& .MuiInputLabel-root": {
      color: "black",
    },

    "& .MuiInput-root": {
      marginTop: 12,
    },
  },

  // On Hover
  "&:hover": {
    "& .MuiInput-root": {
      borderBottom: "0px solid black",
    },
  },
});

export default CustomFileInput;
