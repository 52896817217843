import axios from "axios";
import { apiEndpoint } from "../config";

let axiosConfig = {
  withCredentials: true,
};

class InternshipCertificateServices {
  async AddCertificateDetails(data) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/form/InternCertificate/add`,
        data,
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }
}
const internshipCertificateServices = new InternshipCertificateServices();
export default internshipCertificateServices;
